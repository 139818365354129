@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap');


*{
  margin:0;
  padding:0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  user-select: none;
}

body{
  max-width: 100%;
  overflow-x: hidden;
  margin-top:-2px;
}

:root{
  --color-primary:#00BEFA;
  --color-secondary:#79DAE8;
  --color-3:#192f59;
  --color-white:#fff;
  --color-text:#87879c;
  --color-text2:#6B6B6B;
  --color-purple:#5A14A5;

  --transition:0.7s;
}

.container{
  width:85%;
  margin:10rem auto;

}


/* h1,
h2,
p,
h3{
  color: var(--color-white);
} */


a{
  text-decoration: none;
}

li{
  list-style-type: none;
}

.grid3{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5rem;
  place-items: center;
  text-align: center;
}

.window-scroll{
  background-color: var(--color-primary);
  box-shadow:0 1rem 2rem rgba(0,0,0,0.2);
  height:10vh;
}

html{
  scroll-behavior: smooth;
}

.back{
  background-image: url("../public/images/school.jpg");
  background-position: center;
  height:80vh;
  width:100%;
  background-size:cover;
  top:0;
  position: absolute;
  z-index: -1;
  background-attachment: fixed;
  padding-top:20rem;
  padding-left:7.5%;
  
}

.back h1{
  font-size: 80px;
  font-weight: 600;
  color:whitesmoke;
  border-bottom:4px solid var(--color-purple);
  width:35%;

}

.back h2{
  color:whitesmoke;
  font-weight: 500;
}



.margin-back{
  margin-bottom:30rem;
}