.carousel{
    /* height:100vh; */
    /* margin-top:30rem; */
    /* width:100%; */
    overflow-x: hidden;
    /* position: relative; */
   
}

.carousel-img img{
    height: 65vh;
    /* width:120%; */
    object-fit: cover;
}

.middle-design img{
    /* height:100px; */
    width:42%;
    position: absolute;
    z-index: 9;
    margin-top:-3.8rem;
    margin-left:31.5%;
    
}



.middle-design-text{
    position:absolute;
    z-index: 99;
    color: var(--color-white);
    margin-left:34rem;
    margin-top:15rem;
    
}

.middle-design-text h1{
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.73rem;
    line-height: 2.8rem;
}

.mdl-text{
    margin-left:8rem;
}
.lst-text{
    margin-left:10rem;
}
.middle-design-text h3{
    margin-top:3rem;
    margin-left:5.8rem;
    font-weight: 500;
}

