.thadar-img{
    background-image: url("../../../../public/images/home.jpg");
    background-size:cover;
    width:100%;
    height:100vh;
    background-position: center;
    position:absolute;
    top:0;
    left:0;
    z-index: -1;
    background-attachment: fixed;
}

.thadar h1{
  position: relative;
}
.thadar-text{
    width:50%;
    margin-top:10rem;
    
}

.thadar-text h2{
    color:var(--color-white);
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
}

.thadar-text h1{
    font-size:45px;
    color:var(--color-white);
    margin:10px 0;
    z-index: -1;
    
}

.thadar-text p {
    line-height: 30px;
    font-size: 18px;
    letter-spacing: 1.5px;
    word-spacing: 4px;
  }

  button{
    padding:10px 30px;
    background-color:var(--color-white);
    color: var(--color-primary);
    border:2px solid transparent;
    font-weight: 600;
    margin:30px 18px 30px 0;
    cursor: pointer;
    box-shadow:0px 30px 36px -11px rgba(0 0 0/40%);
    transition: var(--transition); 
    
  }

  button:hover{
    color:var(--color-white);
    background-color: var(--color-primary);
    border:2px solid #fff;
    box-shadow: none;
  }

  .primary-btn{
    color:var(--color-white);
    background-color: var(--color-primary);
    transition: var(--transition);
  }
  .primary-btn:hover{
    color:var(--color-primary);
    background-color: var(--color-white);
    border:2px solid var(--color-primary);
    box-shadow: none;
  }

button i{
  padding:5px 8px;
  font-size: 15px;
}

/* =============MediaQuery(Tablets)================ */
@media screen and (max-width:1028px) {
  .thadar-img{
    height:80vh;
  }

  .thadar-text{
    width:50%;
    margin-top:-6rem;
    
}

.thadar-text h2{
  
  font-weight: 500;
  letter-spacing: 0.8px;
  font-size: 20px;
}

.thadar-text h1{
  font-size:30px;
  
  margin:10px 0;

}

.thadar-text p {
  line-height: 30px;
  font-size: 15px;
  letter-spacing: 1px;
  word-spacing: 4px;
  text-align: justify;
}

button{
  padding:8px 15px;
}
}

@media screen and (max-width:600px){
  .thadar-img{
    height:75vh;
  }

  .thadar-text{
    width:50%;
    margin-top:-6rem;
    
}

.thadar-text h2{
  
  font-weight: 500;
  letter-spacing: 0.8px;
  font-size: 20px;
}

.thadar-text h1{
  font-size:30px;
  
  margin:10px 0;

}

.thadar-text p {
  line-height: 30px;
  font-size: 15px;
  letter-spacing: 1px;
  word-spacing: 4px;
  text-align: justify;
}

button{
  padding:8px 15px;
}
}