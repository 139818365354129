/* .about-img{
    background-image: url("../../../public/images/school.jpg");
    background-size: cover;
    background-position: center;
    height:80vh;
    width:100%;
    top:0;
    left:0;
    z-index: -1;
    position: absolute;
    background-attachment: fixed;
    
} */

/* .margin{
    margin-top:40.3%;
} */

.aboutSec{
    
    width:90%;
    margin-left:5%;
   
    
}



.about-i{
    width:30rem;
    margin:1rem 2rem;
    overflow: hidden;
}

.about-i p{
    text-align: justify;
    color: var(--color-text2);
}

.about-i h1{
    margin: 20px 0;
    font-weight: 600;
    color:var(--color-purple);
}
.about-i img{
    height:150px;
    float:left;
    border-radius: 10px;
    margin:0 20px 10px 0;
    
}

.objeSec ul li i{
    font-size: 6px;
    margin-right:9px;
    margin-bottom:28px;
    color:black;
}

.objeSec li{
    color:var(--color-text2);
}

.messageSec{
    position: relative;
}

.head-m{
    position: absolute;
    border-right: 5px solid var(--color-primary);
    padding:5px 10px;
    background-color: rgba(128,0,128,0.3);
    top:46%;
    right:100rem;
    border-radius:5px 0px 0px 5px;
    transition: 0.5s;
    
}

.aboutSec:hover .head-m{
    right:53%;
}



.head-m h3{
    font-weight: 600;
    color:white;
    
}

.head-m span{
 color:var(--color-primary);
 font-weight: 400;
}


.f{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-bottom:1px solid lightgray;
    padding-bottom: 2rem;
}

.s{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}