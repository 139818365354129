.counter{
    margin:-5rem auto;
    background-color: var(--color-primary);
    width:85%;
    padding:4rem;
    border-radius: 20px;
    margin-bottom: 5rem;
    /* position: absolute; */
    box-shadow:-20px 0px 80px -11px var(--color-primary);
    transition: var(--transition);
}

.counter:hover{
 box-shadow: none;
}

.v-line{
    border-left:2.5px solid #5A14A5;
    height:10rem;
}
.counter-i {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color:#5A14A5;
    width:21.25%;
}

.counter-i h1{
    font-size: 75px;
}


.flex{
    display: flex;
    justify-content: space-evenly;
    
}