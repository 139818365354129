.testimonial{
    margin:10rem auto;
}

.test-head{
    margin:2rem auto;
    text-align: center;
    font-size: 45px;
    font-weight: 800;
    color: var(--color-purple);
}

.con img{
    height:7rem;
    width:7rem;
    border-radius:50%;
    object-fit:cover;
    border:15px solid var(--color-primary)
}

.con{
    padding: 30px 20px;
    user-select: none;
    border-radius: 20px;
    color: var(--color-primary);
    transition: var(--transition);
    height:21.15rem;
   
}

.con:hover{
    background-color: var(--color-primary);
    color:white;
    box-shadow:-20px 20px 20px -11px rgba(0 0 0/40%);
}

.con i{
    font-size: 30px;
    /* color: var(--color-primary); */
    position:absolute;
    margin-left:-10px;

    
}


.test-up{
    display: flex;
}

.test-text{
    margin:20px 25px
}
.con h3{
    color: var(--color-purple);
}

.con p{
    font-weight: 300;
    margin:14px 0 14px 20px;
    color:black !important;
    
}

.test-first{
    height:21.15rem;
    padding:2rem 0.5rem 2rem 0.8rem;
}

.right{
    font-weight: 500;
    letter-spacing: 0.1px;
    margin-left:3rem;
    word-spacing: 0.1px;
    margin-bottom: 1px;
    color:var(--color-primary)
}
.right-img{
    height:7rem;
    width:7rem;
    margin-left:19rem;
    margin-bottom:3px;
    background-color: var(--color-primary);
    padding:0.3rem;
    border-radius: 50%;
    color:white;

}

.swipe-img{
    height: 6.3rem;
    width:6.3rem;
    margin-left:1.5rem;
    background-color: var(--color-purple);
    color:white;
    border-radius: 50%;
    
}

.swipe{
    font-weight: 500;
    margin-bottom:-0.1rem;
    word-spacing: 0.1px;
    letter-spacing: 0.1px;
    color:var(--color-purple);
   
}

