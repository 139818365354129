.contact-container{
    margin-top:5rem;
    width:80%;
    margin-left:10%;
    gap:7rem;
}

.contact-mapSec{
    padding:2rem 1rem;
    width:50%;
    border-top:2px solid var(--color-primary);
    border-bottom:2px solid var(--color-primary);
    border-radius:50px;
    background-color: aliceblue;
}
.mapSec-list li{
    margin: 1rem 0;
}
.mapSec-list li i{
    margin-right: 20px;
}

.con-sec{
    margin:0.8rem 0;
}

.con-sec h3{
    font-weight: 500;
    margin-bottom:5px;
}

.con-sec i{
    font-size: 30px;
    margin:5px 15px 20px 0;
}

iframe{
    height:15rem;
    width:100%;
}

.contact-form{
    padding:2rem 1rem;
    width:50%;
    border-top:2px solid var(--color-primary);
    border-bottom:2px solid var(--color-primary);
    /* border-radius:50px; */
    background-color: aliceblue;
}
.contact-form input{
    width:100%;
    padding:12px 5px;
    margin:0 10px 20px 0;
    outline: none;
    border:2px solid var(--color-purple)
}

.contact-form textarea{
    width:100%;
    padding:10px 5px;
    outline: none;
    border:2px solid var(--color-purple)
}