.noticesEvents{
    display: flex;
    /* flex-direction: row; */
    margin-left:2.8rem;
   
    padding-top:29rem;
    /* height: 100vh; */
}

.events{
    margin: -15rem 45rem 15rem 15rem;
    /* background-color: red; */
    border-right:5px solid var(--color-primary);
    /* width:50%; */
}

.events-wrap{
    margin-top:-2rem;
}

.events-head{
    text-align: center;
    padding-top:1rem;
    border-radius: 10px 10px 0 0;
    background-color: var(--color-primary);
    width:17.5rem;
    height:4rem;
}



.events-con{
    display: flex;
    gap:2rem;
    margin:2rem 0;
    width:30rem;
    background-color: hsl(0, 0%, 96%);
    /* background-color: red; */
    padding:0.5rem;
    
}

.events-con h2{
    font-weight: 600;
    margin-bottom:5px;
    margin-top:5px;
}

.events-con h3{
    font-weight: 500;
}



.calender{
    
    border:5px solid black;
    padding:0.5rem 1.3rem;
    border-radius: 6px;
   
}

.calender i{
    
    margin-left: -1rem;
    
}

.calender h1{
    text-align: center;
}

.notices{
    margin:-15rem 45rem 15rem -40rem;
   
}

.notice-con{
    /* background-color:red; */
    display: flex;
    flex-direction: column;
    width:30rem;
    background-color: hsl(0, 0%, 96%);
    padding:1rem;
    margin:2rem 2rem 2rem -5rem;
   
}

.notice-con p{
    padding-bottom: 8.5px;
border-bottom: 1px solid black;
}

.notice-con h2{
    color: #ceced6;
    margin:5px 0;
    font-weight: 600;
}

.notice-con h3{
    font-weight: 500;
}

.notices-head{
    text-align: center;
    padding-top:1rem;
    border-radius: 10px 10px 0 0;
    background-color: var(--color-primary);
    width:17.5rem;
    height:4rem;
    margin-left:7.5rem;
}

.notice-wrap{
    margin-top: -2rem;;
}

.notices-head, .events-head{
    font-weight: 600;
    color: var(--color-purple)
}


