footer{
    background-image: url("../../../../public/images/footer.jpg");
    height:20rem;
}

.footer{
    display: grid;
    grid-template-columns: 1.5fr 3fr 2fr;
    gap:100px;
    margin: 5rem 180rem 0 12rem;
    padding-top:5rem;
}

.foot{
    width:20rem;
}

.footer h1{
    font-size: 40px;
    color: var(--color-primary)

}

.first span{
    font-weight: 500;
    color: var(--color-purple)
}

.first p{
    font-weight: 400;
    font-size:14px;
    margin: 20px 0 12px ;
}

.footer h3{
    color: var(--color-primary);
    margin-bottom: 10px;
    font-weight: 600;
}

.second li{
    margin:5px 0;
    font-size: 14px;
    font-weight: 300;
}

.second i{
    font-size: 12px;
    color:var(--color-purple);
    margin-right:8px;
}

.third li{
    margin:5px 0;
}

.third i{
    padding:5px 15px 5px 0;
}

.copyright{
    margin-top:10px;
    background-color: #3377c0;
    width:100%;
    height:50px;
    text-align: center;
    padding-top:17px
}

.copyright .span-f{
    padding-right:10px;
    border-right:3px solid black;
}

.span-s{
    padding-left:10px;
}

