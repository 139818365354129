.about{
    margin:18rem auto;
    height: 100vh;
    width:85%;
    
}

.img img{
    object-fit: cover;
    height:58vh;
    width:50vh;
    border-radius: 15px;
    box-shadow:-20px 30px 10px -11px rgba(0 0 0/40%);
    margin-right:3rem;
}



.school-description{
    width:30rem;
    margin-right:30rem;
}

.school-description p{
    color:var(--color-text);
    font-weight: 400;
    line-height: 2rem;
}

.school-description h1{
    color: var(--color-primary)
}

.school-description button{
    margin-top:17px;
}

.principal-section{
    display: flex;
    flex-direction: row-reverse;
}

.principal-img img{
    object-fit: cover;
    height:85vh;
    width:85vh;
    border-radius: 15px;
    /* box-shadow:-20px 30px 10px -11px rgba(0 0 0/40%); */
    margin-left:45rem;
}

.principal-word{
    width:25rem;
    margin-right:-55rem;
    margin-top:5vh;
    height:75vh;
    background-color: #F5F5F5;
    border-radius: 15px;
    padding:10vh 5vh;
    box-shadow:-20px 30px 10px -11px rgba(0 0 0/40%);
}

.principal-word h1{
 color:var(--color-primary);
 font-weight: 700;
}

.principal-word h3{
    font-weight: 500;
    text-transform: uppercase;
    margin:0 0 15px;
    font-weight: 500;
}

.principal-word h4{
    font-weight: 600;
    font-size:0.9rem;
    margin:25px 0;
}

.principal-word p{
    line-height: 2rem;
}