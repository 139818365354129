.teamSec{
    width:85%;
    margin:0 auto;
}

.teamSec h1{
    text-align: center;
   font-size: 40px;
   font-weight: 700;
   margin-bottom:4rem;
}

.team{
    background-color:var(--color-primary);
    margin: 20px 20px;
    /* height:22rem; */
    /* width:20rem; */
    padding:20px 0;
    border:2px solid transparent;
    transition: var(--transition);
    position: relative;
    overflow: hidden;

    
}

.team:hover{
    border:2px solid var(--color-primary);
    background-color:white;
    
}
.team img{
    height:18rem;
    width:15rem;
    object-fit:cover;
    /* padding:2px 0px; */
    filter: saturate(0);
}

.team:hover img{
    filter:saturate(1);
}
.team h3{
    font-weight: 600;
    margin:5px 0 3px 0;
    font-size: 20px;
}

.team p{
    font-size: 15px;
    font-weight: 500;
    margin-bottom:-0.5rem;
}

.t-first{
    text-align: center;
    display:grid;
    grid-template-columns: repeat(4, 1fr);

}

.team-btn{
    text-align: center;
    margin:-2rem auto 2rem;
    border-bottom: 3px solid var(--color-purple);
    border-top:3px solid var(--color-purple);
    width:56%;
    background-color: aliceblue;
    
}

.team-btn button{
    box-shadow: none;
}











.team-socials{
    background-color: var(--color-purple);
    position: absolute;
    display: flex;
    flex-direction: column;
    padding:1.5rem 0.8rem;
    border-radius: 15px 0 0 15px;
    top:30%;
    right:-100rem;
    box-shadow: -2rem 0 2rem rgba(0,0,0,0.3);
    transition:0.5s;
   
    
}

.team:hover .team-socials{
    right:-1px;
}

.team-socials i{
    padding:8px 0;
    color:var(--color-primary);
    font-size: 23px;
    
}