

.head{
    background-color: var(--color-primary);
    height:fit-content;
    width:65rem;
    margin-right:25%;
    border-radius: 0 0 10px 0;
    display: flex;
    justify-content: space-between;
    padding:2px 15px;
    color:#fff;
    align-items: center;
    
}




.flexSB{
    display: flex;
    justify-content: space-between;
}
.head-left{
    align-items: center;
    /* justify-content: center; */
}
.head-left h3{
    margin-right:50px;
    margin-left:7px;
}

.head-left i{
    margin-top:2px;
    margin-right:3px;
    font-size:large;
}

.head-right i{
    margin-right:15px;
    
}
.how{
    /* position:-webkit-sticky; */
     position:sticky; 
     top:0;
     z-index: 999;
}
.header{
    background-color: rgba(255, 255, 255, 0.2);
    
    width:100%;
    z-index: 99999;
    transition: height 1s;
    height:14vh;
    margin-top:0px;
    

}



.nav-link ul li{
    margin: auto -20px;
    
}



.window-scroll{
    background-color: var(--color-primary);
    box-shadow:0 1rem 2rem rgba(0,0,0,0.2);
    height:12vh;
    
}
 
 
.nav-link{
    
    width:100%;
    
    margin-top:7px;
    
    
}


.logo img{
    height:6rem;
    width:6rem;
    margin-left:5rem;
    margin-top:0rem;   
}

.logo-title{
    margin-top:5rem;
}

.logo{
    align-items: center;
    height:14vh;
    padding-right:5rem;
    clip-path: polygon(0 0, 95% 0, 75% 100%, 0% 100%);
    background-color: #5A14A5;
   
    display:flex;
    justify-content: space-between;
    transition: height 1s;
    
}

.logo-new{
    height:12vh;
}



.logo h2{
 color:#fff;
 font-weight: 700;
}

.logo-title{
    margin-top:0rem;
}

 
.nav-ul{
    margin: 40px 0 0 0;
}
 .nav-ul  li{
    position: relative; 
     float:left;
    
    
   
    
    
 }
 .nav-ul li::before{
    content:"";
    left:-5%;
    position:absolute;
    width:0%;
    height:4px;
    background:var(--color-primary);
    bottom:-20%;
    margin-right:10px;
    transition: all 0.45s;
   
}
/* .nav-ul li:nth-child(4)::before,
.nav-ul li:nth-child(5)::before,
.nav-ul li:nth-child(2)::before{
    width:0%;
    
} */

.nav-ul li:hover::before{
    width:50%
}

.nav-ul li:nth-child(4):hover::before,
.nav-ul li:nth-child(2):hover::before,
.nav-ul li:nth-child(5):hover::before{
    width:60%;
}


ul li a{
    margin-right:80px;
    font-weight:bolder;
    color:#fff;
    transition:var(--transition);
}

ul li a:hover{
    color:var(--color-secondary)
}

.menu-btn{
    display: none;
}







/* ===========(MQ)Tablet============= */
@media (max-width:1028px ){
.head{
    width:100%;
    border-radius: 0;
    padding:8px 0 8px 40px;
   
}

.head-right{
    display: none;
}

.head-left{
    margin-left:5%;
}

.head h3{
    font-size: smaller;
    font-weight: 500;  
}
.head-left i{
    font-size: smaller;
}

.nav-ul{
    display: none;
}


.nav-ul-mobile{
    position: fixed;
    top:10rem;
    right:-5%;
    display:flex;
    flex-direction: column;
    /* gap:1rem; */
    width: 15rem;
    height:fit-content;
    /* display: none; */
    transition: 0.5s;
   
}


 .menu-btn{
     display: block;
     border:none;
     background-color: transparent;
     box-shadow: none;
     margin-top:-1.2rem;
     
 }
.menu-btn:hover{
    border:none;
    background-color: transparent;
    box-shadow: none;
}
 .menu-btn i{
    font-size: 35px;
    margin-top:37px;
    margin-right:3rem;
}
.nav-ul-mobile li{
    background-color: rgba(255, 255, 255, 0.2);
    width:100%;
    height: 100%;
    /* display: grid;
    place-items: center; */
    box-shadow: -4rem 6rem 10rem rgba(0,0,0,0.6);
    padding:10px 0 10px 50px; 
}

.nav-ul li:hover{
    background-color: aliceblue;
}
}
    
/* =============MQ(Mobile)============ */

@media (max-width:600px) {
.head{
    display: none;
}

.header{
    margin-top:-20px;
    height:10vh;
}

.logo{
    height:10vh;
    clip-path: polygon(0 0, 100% 0, 64% 100%, 0% 100%);
}

.logo img{
    height:4rem;
    margin-left:35px;
}

.logo .logo-title{
   display: none;
}

.menu-btn{
    margin-top:-2rem;
    color:#fff;
}

.menu-btn i{
    font-size:25px;
}

    .nav-ul-mobile{
        width:100%;
      margin-top:-5.6rem;
      margin-right:10px;
    }

    .nav-ul-mobile li{
        color: #5A14A5;
        background-color: #5A14A5;
    
    }
}